<template>
  <div class="container">
    <strong class="strongAlert">Correo aún no confirmado</strong>
    <span class="spanAlert">Aún no has activado tu cuenta.</span>
    <button
      class="buttonAlert"
      @click="(is_verified = true), resend({ email: userData.email })"
    >
      Reenviar correo
    </button>
  </div>
</template>

<script>
import service from "@/services/others";

export default {
  methods: {
    resend(data) {
      //this.isVerified = true;
      service.sendEmailPass(data, true).then(() => {});
      this.$emit('close-toast')
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    is_verified: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isVerified: this.userData.is_verified,
    };
  },
};
</script>

<style>
.strongAlert {
  display: block;
  width: 100%;
}
.spanAlert {
  display: block;
  padding: 5px 0px 10px 0px;
}
.buttonAlert {
  padding: 5px;
  color: white;
  font-weight: bold;
  background-color: #e75455;
  border-radius: 5px;
  border: 0px;
}

.buttonAlert:hover {
  box-shadow: 1px 5px 10px #ccc;
}
.buttonAlert:focus {
  outline: none;
}
</style>
