<template>
  <div class="navbar-container d-flex content align-items-center" :style="bgColor">

    <!-- Nav Menu Toggler -->
    <div class="d-block d-xl-none w-100 h-navbar-main">
      <ul class="nav navbar-nav">
        <li class="nav-item col-12 col-sm-auto">
          <b-link
            class="nav-link d-block"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="text-white menu-icon-navbar"
            />
            <span class="d-flex justify-content-center ml-0 ml-sm-3">
              <b-img
                v-if="checkCoca()"
                src="@/assets/images/logo/coca_logo.png"
                alt="Logo"
                style="max-width: 100%"
              />
              <b-img
                v-else-if="checkOutHome()"
                fluid
                src="https://outofhome.mx/wp-content/uploads/2022/02/OutOfHome-02-1024x553-1.png"
                alt="Logo"
                style="max-width: 100px"
                class="position-absolute-logo-ooh"
              />
              <b-img
                v-else
                src="@/assets/images/logo/logo-brandme.png"
                alt="Logo"
                style="max-width: 100px"
              />
          </span>
          </b-link>
        </li>
      </ul>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto navbar-options-user">
      <button-modal class="d-none d-sm-block"/>
      <locale class="d-none d-sm-block"/>
      <dark-Toggler class="d-none d-lg-block" />
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import ButtonModal from '../ButtonModal.vue'
import useAppConfig from "@core/app-config/useAppConfig";
import { watch } from '@vue/composition-api'

export default {
  data () {
    return {
      bgColor: '',
      dominio: window.location.host
    }
  },
  components: {
    BImg,
    BLink,
    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
    UserDropdown,
    ButtonModal,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    const { skin } = useAppConfig();
      
    watch(skin , valu => {
      if (valu === 'dark' && this.dominio.includes('outofhome'))
        this.bgColor = 'background: #273045'
      else if (this.dominio.includes('outofhome')) this.bgColor = 'background: #e74353'
    })
  },
  created () {
    if (this.dominio.includes('coca')) this.bgColor = 'background: #f01f33'
    else if (this.dominio.includes('outofhome')) this.bgColor = 'background: #e74353'
    else this.bgColor = 'background: linear-gradient(120deg ,#6947bc, #993e90'
  },
  methods: {
    checkCoca() {
      return this.dominio.includes('coca')
    },
    checkOutHome() {
      return this.dominio.includes('outofhome')
    },
  }
}
</script>
<style>
.bm-logo{
  margin-left: 15px;
  margin-top: 8px;
}
.menu-icon-navbar {
  position: absolute;
  top: 5px;
}
.h-navbar-main {
  height: 35px !important;
}
@media (max-width: 575px) {
  .position-absolute-logo-ooh {
    position: absolute;
    top: -8px;
  }
}
@media (min-width: 575px) {
  .position-absolute-logo-ooh {
    position: absolute;
    top: -8px;
    left: 2.5em;
  }
  .navbar-options-user {
    position: absolute;
    right: 2em;
  }
}
</style>
