<template>
  <div class="navbar-header d-xl-block d-none" style="width: 300px;">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          :to="checkRedirect()"
        >
          <span class="brand-logo">
            <b-img
              v-if="checkCoca()"
              src="@/assets/images/logo/coca_logo.png"
              alt="Logo"
              style="max-width: 100%"
            />
            <b-img
              v-else-if="checkOutHome()"
              fluid
              src="https://outofhome.mx/wp-content/uploads/2022/02/OutOfHome-02-1024x553-1.png"
              alt="Logo"
              style="max-width: 100px"
            />
            <b-img
              v-else
              src="@/assets/images/logo/logo-brandme.png"
              alt="Logo"
              style="max-width: 100px"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg
  },
  created () {
    this.rutaDominio = window.location.host
  },
  methods: {
    checkCoca() {
      return this.rutaDominio.includes('coca')
    },
    checkOutHome() {
      return this.rutaDominio.includes('outofhome')
    },
    checkRedirect() {
      if (this.checkOutHome()) return '/campaigns'
      return '/'

    }
  }
}
</script>

<style>

</style>
