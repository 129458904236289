<template>
  <div>
    <b-button
      @click="$router.push('/pages/account-setting/visitedProfiles')"
      class="btn-sm buttonMaxviews"
      v-if="user && user.main_group && user.main_group.name != 'creator' && user.main_group.name != 'realty'"
    >
      {{ $t('maxviews')}} {{max_view}}
    </b-button>
  
    <b-button v-if="user && user.main_group && user.main_group.name === 'brand'" class="btn-sm blue-button p-0" variant="blue-button"> 
      <a href="https://brandme.la/solicitar-propuesta/" target="_blank" class="text-white premiuma" >{{$t('updateToPremium') }}</a> 
    </b-button>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import utils from '@/libs/utils'

import service_profile from '@/services/others'
export default {
  name: 'ButtonModal',
  components: {
    BButton,
  },
  data() {
    return {
      utils,
      max_view: 0,
      user: utils.getUserData()
    }
  },
  created () {
    this.$root.$on('change_max_views', (max_views) => {
      this.max_view = max_views
    })

    if (this.user) this.getMaxViews()
  },
  methods: {
    getMaxViews() {
      service_profile.getMaxViews()
        .then(response => {
          this.max_view = response.max_views
        })
    }
  }
}
</script>
<style>
  .buttonMaxviews {
    background-color:#9449fa !important;
    margin-right: 10px;
    border: #9449fa;
  }
  .buttonMaxviews:hover{
    background-color:#792fe3 !important;
  }
  .buttonMaxviews:focus{
    background-color:#9449fa !important;
     border: #9449fa;
  }
  .premiuma {
    display: block;
    padding: 7px 10px 7px 10px; 
  }

</style>
